<template>
  <v-card>
    <validation-observer ref="obs" v-slot="{ errors, invalid, valid }">
      <v-card-text>
        <v-card class="mb-4" outlined>
          <v-toolbar color="titleBar" dark flat dense>
            <v-toolbar-title>{{ $t("order details") }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <hardware-shop-delivery
              v-model="deliveryAddress"
              :reference="reference"
              :validation-data="orderData"
              :receipt="deliveryReceipt"
              show-delete-address
              show-reset-address
              show-receipt-preview
              show-receipt-edit
              @receiptSaved="deliveryReceipt = $event"
            />

            <v-row>
              <v-col cols="12">
                <validation-provider
                  vid="product_description"
                  :name="$t('product description')"
                  rules="max:100"
                  v-slot="{ errors, valid, dirty, classes }"
                >
                  <v-text-field
                    v-model="productDescription"
                    name="product_description"
                    :label="$t('product description')"
                    :hint="$t('product description appears on invoice')"
                    :error-messages="errors"
                    :success="dirty && valid"
                    :class="classes"
                    counter="100"
                    :clearable="!isMobile"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="orderNote"
                  :label="$t('order note')"
                  maxlength="1024"
                  rows="1"
                  :clearable="!isMobile"
                  counter
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <email-list-input
                  v-model="orderEmail"
                  :label="$t('confirmation email')"
                  max-length="1024"
                  multiple
                />
              </v-col>
              <v-col cols="6">
                <language-select
                  v-model="orderLang"
                  :label="$t('delivery receipt language')"
                  :codes="['en', 'de']"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="$router.go(-1)" :disabled="loading" text>
          {{ $t("back") }}
        </v-btn>
        <v-btn
          color="primary"
          @click="commitOrder()"
          :disabled="
            loading || invalid || $store.getters.isReadOnly || itemsInCart == 0
          "
          :loading="loading"
          text
        >
          <v-icon class="mr-2">mdi-cash-register</v-icon>
          {{ $t("order") }}
        </v-btn>
      </v-card-actions>
    </validation-observer>
  </v-card>
</template>

<script>
import EmailListInput from "@/components/basics/EmailListInput";
import LanguageSelect from "@/components/basics/LanguageSelect";
import HardwareShopDelivery from "@/components/orders/hardware/HardwareShopDelivery";
import showErrors from "@/utils/mixins/showErrors";
import isMobile from "@/utils/mixins/isMobile";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } =
  createNamespacedHelpers("hardwareShop");

export default {
  name: "HardwareShopOrder",
  mixins: [showErrors, isMobile],
  components: {
    EmailListInput,
    LanguageSelect,
    HardwareShopDelivery,
  },
  data: () => ({
    loading: false,
    orderEmail: "",
    orderNote: "",
    orderLang: "",
    deliveryAddress: null,
    deliveryReceipt: "",
    productDescription: "",
  }),
  computed: {
    ...mapState(["items", "card", "reference"]),
    ...mapGetters([
      "itemsInCart",
      "cartItems",
      "priceMonthly",
      "priceFix",
      "shippingItem",
    ]),
    orderData() {
      var data = {
        orders: this.cartItems.map(function (item) {
          return {
            variant: item.priceItem.id,
            quantity: item.quantity,
          };
        }),
        reference: this.reference,
        delivery_receipt: (this.deliveryReceipt || "").trim() || null,
        delivery_address: this.deliveryAddress,
        email: this.orderEmail,
        note: this.orderNote,
        language: this.orderLang,
        product_description: this.productDescription || null,
      };
      if (this.shippingItem != null)
        data.orders.push({
          variant: this.shippingItem.id,
          quantity: 1,
        });
      return data;
    },
  },
  watch: {
    orderEmail: {
      immediate: true,
      handler(value) {
        this.orderEmail = value || this.$store.getters.deliveryEmail;
      },
    },
    orderLang: {
      immediate: true,
      handler(value) {
        this.orderLang = value || this.$store.state.locale;
      },
    },
  },
  methods: {
    ...mapMutations(["resetCart", "setReference", "setLoaded"]),
    commitOrder() {
      var that = this;
      this.loading = true;
      this.$http
        .post("shop/hardware/order", this.orderData)
        .then((response) => {
          that.$snotify.success(that.$i18n.t("notifyMsg"));
          // rest cart, delete reference and force reload hardware items
          that.resetCart();
          that.setReference(null);
          that.setLoaded(false);
          that.$route.meta.keepAlive = false; // drop component from cache
          that.$router.push({ name: "hardware-list" });
          that.$route.meta.keepAlive = true;
        })
        .catch((error) => {
          that.$refs.obs.setErrors(error.data);
          that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
        })
        .finally(function () {
          that.loading = false;
        });
    },
  },
};
</script>

<i18n>
{
  "en": {
    "order details": "Order details",
    "back": "Back",
    "order contact": "Order contact",
    "delivery address": "Delivery address",
    "order": "Order",
    "email": "Email",
    "confirmation email": "Confirmation email",
    "order note": "Own note for order confirmation",
    "phone": "Phone",
    "order contact phone number": "Order contact phone number",
    "mobile": "Mobile",
    "order contact mobile number": "Order contact mobile number",
    "product description": "Product description",
    "product description appears on invoice": "product description appears on invoice",
    "delivery receipt language": "Delivery receipt language",
    "notifyMsg": "Your order has been successfully committed."
  },
  "de": {
    "order details": "Bestelldetails",
    "back": "Zurück",
    "order contact": "Bestellkontakt",
    "delivery address": "Lieferadresse",
    "order": "Bestellen",
    "email": "Email",
    "confirmation email": "Bestätigungs Email",
    "order note": "Eigene Notiz für Bestellbestätigung",
    "phone": "Telefon",
    "order contact phone number": "Bestellkontakt Telefonnummer",
    "mobile": "Mobil",
    "order contact mobile number": "Bestellkontakt Mobilnummer",
    "product description": "Produktbeschreibung",
    "product description appears on invoice": "Produktbeschreibung erscheint auf der Rechnung",
    "delivery receipt language": "Lieferschein Sprache",
    "notifyMsg": "Ihre Bestellung wurde erfolgreich übermittelt."
  }
}
</i18n>
